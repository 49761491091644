import {ReactElement, useState} from "react";
import AccountIcon from "./AccountIcon";
import {FormattedMessage} from "react-intl";
import ArrowDown from "./ArrowDown";
import {Transition} from '@headlessui/react';
import {redirectTo} from "../../lib/utility";

export interface AccountMenuProps {
	isAnonymous: boolean
}

export default function AccountMenu(props: AccountMenuProps): ReactElement {

	const [show, setShow] = useState(false);

	return (
		<>
			<div className={"tw-flex tw-flex-row tw-items-center tw-cursor-pointer"}
				 onMouseEnter={() => setShow(true)}
				 onMouseLeave={() => setShow(false)}
				 onClick={() => redirectTo("/my-account/overview")}>
				<AccountIcon/>
				<div
					className={"tw-uppercase tw-font-gotham tw-font-bold tw-pl-4 tw-pr-4 tw-flex tw-items-center tw-flex-row"}>
					<FormattedMessage
						id={props.isAnonymous ? "frontend.topnavbarmenu.login" : "frontend.topnavbarmenu.my.account"}/>
					<ArrowDown/>
				</div>
			</div>
			<Transition
				onMouseEnter={() => setShow(true)}
				onMouseLeave={() => setShow(false)}
				className={"tw-absolute tw-flex tw-flex-col tw-right-60 tw-top-16 tw-bg-white tw-shadow-desktop-menu"}
				enter="tw-transition-all tw-duration-300"
				enterFrom="tw-opacity-0"
				enterTo="tw-opacity-100"
				leave="tw-transition-opacity tw-duration-300"
				leaveFrom="tw-opacity-100"
				leaveTo="tw-opacity-0"
				show={show}>
				{props.isAnonymous &&
					<>
						<div className={`tw-bg-black tw-m-4 tw-mt-6 tw-p-4 tw-flex tw-flex-1 tw-justify-center tw-text-white tw-font-bold
                                        hover:tw-bg-white hover:tw-text-black hover:tw-border-black tw-border tw-cursor-pointer`}
							 onClick={() => redirectTo("/login")}>
							<FormattedMessage id={"login.login"}/>
						</div>
						<div className={"tw-px-6 tw-pb-6 tw-bt-2 tw-border-homeComponentsBg tw-border-b"}>
							<FormattedMessage id={"frontend.topnavbarmenu.new.customer"}/>
							<span className={"tw-ml-2 tw-font-bold tw-underline tw-cursor-pointer"}
								  onClick={() => redirectTo('/register')}>
                                <FormattedMessage id={"frontend.topnavbarmenu.register.now"}/>
                            </span>
						</div>
					</>
				}
				<div
					className={`tw-px-6 tw-py-4 hover:tw-bg-homeComponentsBg tw-cursor-pointer ${props.isAnonymous ? '' : 'tw-pt-8'}`}
					onClick={() => {
						props.isAnonymous ? redirectTo('/login') : redirectTo('/my-account/overview')
					}}>
					<FormattedMessage id={"frontend.topnavbarmenu.my.account.overview"}/>
				</div>
				<div className={"tw-px-6 tw-py-4 tw-cursor-pointer hover:tw-bg-homeComponentsBg"} onClick={() => {
					props.isAnonymous ? redirectTo('/login') : redirectTo('/my-account/orders')
				}}>
					<FormattedMessage id={"frontend.topnavbarmenu.orders"}/>
				</div>
				<div className={"tw-px-6 tw-py-4 tw-cursor-pointer hover:tw-bg-homeComponentsBg"} onClick={() => {
					props.isAnonymous ? redirectTo('/login') : redirectTo('/my-account/age-verification')
				}}>
					<FormattedMessage id={"frontend.myaccount.ageVerification"}/>
				</div>
				{!props.isAnonymous &&
					<>
						<div className={"tw-px-6 tw-py-4 tw-cursor-pointer hover:tw-bg-homeComponentsBg"}
							 onClick={() => redirectTo('/my-account/personal-data')}>
							<FormattedMessage id={"frontend.topnavbarmenu.personal.data"}/>
						</div>
						<div className={"tw-px-6 tw-py-4 tw-cursor-pointer hover:tw-bg-homeComponentsBg"}
							 onClick={() => redirectTo('/my-account/address')}>
							<FormattedMessage id={"frontend.topnavbarmenu.address"}/>
						</div>
						<div className={"tw-px-6 tw-py-4 tw-cursor-pointer hover:tw-bg-homeComponentsBg"}
							 onClick={() => redirectTo('/my-account/shipping')}>
							<FormattedMessage id={"frontend.myaccount.shipping.detail"} />
						</div>
                        <div className={"tw-px-6 tw-py-4 tw-cursor-pointer hover:tw-bg-homeComponentsBg"}
                             onClick={() => redirectTo('/my-account/wallet')}>
                            <FormattedMessage id={"frontend.myaccount.wallet.title"}/>
                        </div>
					</>
				}
				<div className={"tw-px-6 tw-py-4 tw-cursor-pointer hover:tw-bg-homeComponentsBg"}
					 onClick={() => redirectTo('/my-account/help')}>
					<FormattedMessage id={"frontend.topnavbarmenu.help"}/>
				</div>
				{!props.isAnonymous &&
					<>
						{/*<div*/}
						{/*	className={"tw-px-6 tw-py-4 tw-pb-4 tw-cursor-pointer hover:tw-bg-homeComponentsBg"}*/}
						{/*	onClick={() => redirectTo('/my-account/shipping')}>*/}
						{/*	<FormattedMessage id={"frontend.topnavbarmenu.checkout.settings"}/>*/}
						{/*</div>*/}
						<div
							className={"tw-px-6 tw-py-4 tw-pb-4 tw-mb-4 tw-cursor-pointer hover:tw-bg-homeComponentsBg"}
							onClick={() => redirectTo('/my-account/delete')}>
							<FormattedMessage id={"frontend.myaccount.customer.delete"}/>
						</div>
						<div
							className={"tw-px-6 tw-py-6 tw-cursor-pointer tw-border-homeComponentsBg tw-border-t tw-pr-72 hover:tw-bg-homeComponentsBg"}
							onClick={() => {
								// @ts-ignore
								window.dataLayer.push({
									'event': 'Tracking Event',
									'category': 'User Tracking',
									'action': 'Logout Success',
									'label': window.location.href
								});
								redirectTo('/logout')
							}}>
                            <span className={"tw-font-bold"}>
                                <FormattedMessage id={"frontend.topnavbarmenu.logout"}/>
                            </span>
						</div>
					</>
				}
			</Transition>
		</>
	);

}
import {ReactElement, useState} from "react";
import {FormattedMessage} from "react-intl";
import ArrowDown from "./ArrowDown";
import {Transition} from '@headlessui/react'
import {addUrlParam} from "lib/urlParsing";
import {useBlLocale} from "components/bundle-entry/i18n/I18n";

export interface LanguageMenuProps {
}

export default function LanguageMenu(props: LanguageMenuProps): ReactElement {

	const locale = useBlLocale();
	const languages = ['de_CH', 'fr', 'it'];
	const [show, setShow] = useState(false);

	return (
		<div className={"tw-mr-8 tw-cursor-pointer"}>
			<div className={"tw-flex tw-flex-row tw-items-center"}
				 onMouseEnter={() => setShow(true)}
				 onMouseLeave={() => setShow(false)}
				 onClick={() => setShow(!show)}>
				<FormattedMessage id={"lang." + locale.substring(0, 2)}/>
				<ArrowDown/>
			</div>
			<Transition
				onMouseEnter={() => setShow(true)}
				onMouseLeave={() => setShow(false)}
				style={{marginLeft: '-20px'}}
				className={"tw-absolute tw-top-16 tw-bg-white tw-shadow-desktop-menu"}
				enter="tw-transition-all tw-duration-300"
				enterFrom="tw-opacity-0"
				enterTo="tw-opacity-100"
				leave="tw-transition-opacity tw-duration-300"
				leaveFrom="tw-opacity-100"
				leaveTo="tw-opacity-0"
				show={show}>
				{languages.filter(key => key !== locale).map(language => (
					<div className={"tw-w-full tw-p-4 tw-pr-16 hover:tw-bg-homeComponentsBg"}
						 key={language}
						 onClick={() => addUrlParam(document.location.search, 'blLocaleCode', language)}>
						<FormattedMessage id={"lang." + language.substring(0, 2)}/>
					</div>
				))}
			</Transition>
		</div>
	);

}

import {ReactElement, useState} from "react";
import {ReactComponent as FavoritesIconGold} from "../../widgets/favorite-icons/favorites-icon-gold.svg";
import {FormattedMessage} from "react-intl";
import {redirectTo} from "../../lib/utility";
import ArrowDown from "./ArrowDown";
import {Transition} from '@headlessui/react';

export interface MyArticlesMenuProps {
	isAnonymous: boolean
}

export default function MyArticlesMenu(props: MyArticlesMenuProps): ReactElement {

	const [show, setShow] = useState(false);

	return (
		<div>
			<div className={"tw-flex tw-flex-row tw-items-center tw-mr-8 tw-cursor-pointer"}
				 onMouseEnter={() => setShow(true)}
				 onMouseLeave={() => setShow(false)}
				 onClick={() => {
					 props.isAnonymous ? redirectTo("/favorites") : redirectTo("/my-articles/last-articles")
				 }}>
				<FavoritesIconGold/>
				<div className={"tw-uppercase tw-font-gotham tw-font-bold tw-pl-4 tw-pr-4"}>
					{props.isAnonymous ?
						<FormattedMessage id="frontend.topnavbarmenu.favorites"/> :
						<FormattedMessage id="frontend.topnavbarmenu.my.articles"/>
					}
				</div>
				{!props.isAnonymous &&
					<ArrowDown/>
				}
			</div>
			<Transition
				onMouseEnter={() => setShow(true)}
				onMouseLeave={() => setShow(false)}
				style={{marginLeft: '-20px'}}
				className={"tw-absolute tw-flex tw-flex-col tw-top-16 tw-bg-white tw-shadow-desktop-menu"}
				enter="tw-transition-all tw-duration-300"
				enterFrom="tw-opacity-0"
				enterTo="tw-opacity-100"
				leave="tw-transition-opacity tw-duration-300"
				leaveFrom="tw-opacity-100"
				leaveTo="tw-opacity-0"
				show={show && !props.isAnonymous}>
				<div>
					<div
						className={"tw-pl-8 tw-pb-4 tw-pt-4 tw-mt-4 tw-cursor-pointer tw-pr-36 hover:tw-bg-homeComponentsBg"}
						onClick={() => redirectTo("/my-articles/last-articles")}>
						<FormattedMessage id={"frontend.topnavbarmenu.articles.rebuy"}/>
					</div>
					<div
						className={"tw-pl-8 tw-pt-4 tw-pb-4 tw-mb-4 tw-cursor-pointer tw-pr-36 hover:tw-bg-homeComponentsBg"}
						onClick={() => redirectTo("/favorites")}>
						<FormattedMessage id={"frontend.topnavbarmenu.favorites"}/>
					</div>
                    {/*<div*/}
					{/*	className={"tw-pl-8 tw-pt-4 tw-pb-4 tw-mb-4 tw-cursor-pointer tw-pr-36 hover:tw-bg-homeComponentsBg"}*/}
					{/*	onClick={() => redirectTo("/favorites/inclusive-last-orders")}>*/}
					{/*	<FormattedMessage id={"frontend.topnavbarmenu.favoritesInclusiveLastOrders"}/>*/}
					{/*</div>*/}
				</div>
			</Transition>
		</div>
	);

}
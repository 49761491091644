import {ReactElement, useEffect} from "react";
import LanguageMenu from "./LanguageMenu";
import MyArticlesMenu from "./MyArticlesMenu";
import AccountMenu from "./AccountMenu";
import {favoriteActions, favoriteProductListReducer} from "../favorites/favoriteListSlice";
import {useAppDispatch} from "lib/redux/reduxHooks";
import {reduxStore} from "lib/redux/reduxStore";
import {
    favoriteInclusiveLastOrdersActions,
    favoriteProductInclusiveLastOrdersListReducer
} from "../favorites-inclusive-last-orders/favoriteInclusiveLastOrdersSlice";

reduxStore.injectReducer("favoriteProductList", favoriteProductListReducer);
reduxStore.injectReducer("favoriteProductInclusiveLastOrdersList", favoriteProductInclusiveLastOrdersListReducer);

export interface TopNavBarMenuProps {
    isAnonymous: boolean
}

export default function TopNavBarMenu(props: TopNavBarMenuProps) : ReactElement {

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(favoriteActions.fetchFavoriteProductList(
            props.isAnonymous
        ));
    }, [dispatch, props.isAnonymous]);

    useEffect(() => {
        dispatch(favoriteInclusiveLastOrdersActions.favoriteProductInclusiveLastOrdersList(
            props.isAnonymous
        ));
    }, [dispatch, props.isAnonymous]);

    return(
        <div className={"tw-flex tw-flex-row tw-flex-1 tw-items-center"}>
            <LanguageMenu />
            <MyArticlesMenu
                isAnonymous={props.isAnonymous} />
            <AccountMenu
                isAnonymous={props.isAnonymous} />
        </div>
    );

}

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {getBlLocaleFromString} from "lib/BlLocale";
import {BundleEntry} from "components/bundle-entry/BundleEntry";
import TopNavBarMenu from "./components/topnavbar-menu/TopNavBarMenu";

const target = document.getElementById('topnavbar-menu');
const rawLocaleCode = target?.dataset?.['localeCode'];
const localeCode = getBlLocaleFromString(rawLocaleCode);
const isAnonymous = target?.dataset?.['isAnonymous'];

ReactDOM.render(
    <BundleEntry
        bundleName="topnavbar-menu"
        locale={localeCode}
        prefetchTranslations={[
            "frontend.topnavbarmenu.my.account",
            "frontend.topnavbarmenu.favorites",
            "lang.de",
            "lang.fr",
            "lang.it",
            "frontend.topnavbarmenu.new.customer",
            "frontend.topnavbarmenu.register.now",
            "frontend.topnavbarmenu.my.account",
            "frontend.topnavbarmenu.orders",
            "frontend.topnavbarmenu.help",
            "frontend.topnavbarmenu.login",
            "login.login",
            "login.already.customer",
            "frontend.topnavbarmenu.personal.data",
            "frontend.topnavbarmenu.address",
            "frontend.topnavbarmenu.checkout.settings",
            "frontend.topnavbarmenu.last.buys",
            "frontend.topnavbarmenu.logout",
            "frontend.topnavbarmenu.my.account.overview",
            "frontend.topnavbarmenu.my.articles",
            "frontend.topnavbarmenu.articles.rebuy",
            "frontend.topnavbarmenu.articles.last.buy",
            "frontend.myaccount.customer.delete",
            "account.changePasswordTitle",
            "frontend.myaccount.wallet.title",
            "frontend.myaccount.ageVerification"
        ]}
    >
        <TopNavBarMenu isAnonymous={isAnonymous === 'true'} />
    </BundleEntry>,
    target
);

import {ReactElement} from "react";

export default function ArrowDown() : ReactElement {

    return(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.11496 8.29508L5.70496 9.70508L11.705 15.7051L17.705 9.70508L16.295 8.29508L11.705 12.8751L7.11496 8.29508Z" fill="#000000"/>
        </svg>
    );

}